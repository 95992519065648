<template>
    <div class="StudentHome">
        <!-- 头部 -->
        <div class="home-title">
            <div class="schoolname">所属学校： 一课大学</div>
            <div class="roomname">直播间名称： {{role === 4 ? '新品预热穿搭' : '小明的直播间'}}</div>
        </div>

        <el-divider></el-divider>
        <!-- 数据展示 -->
        <div class="home-dataContent">
            <div class="dataContent-item" v-for="(item, index) in contentData" :key="index">
                <div class="item_left">
                    <img class="icon" :src="item.src" alt="">
                    <div class="data">
                        <div class="contentText">{{ item.dataName }}</div>
                        <div class="contentNum">{{ item.num }}</div>
                        <div class="contentMoon">{{ item.moonsTip }}: {{ item.moonNum }}</div>
                    </div>
                </div>
                <img class="bigicon" :src="item.bigSrc" alt="" width="80px">
            </div>
        </div>

        <el-divider></el-divider>

        <div class="home-contentCharts">
            <el-tabs v-model="activeName" class="homeTab" type="card" @tab-click="handleClick">
                <el-tab-pane label="直播场次" name="one">
                    <div ref="severalRef" style="width: 100%;height: 400px;"></div>
                </el-tab-pane>
                <el-tab-pane label="观看人数" name="two">
                    <div ref="peopleRef" style="width: 100%;height: 400px;"></div>
                </el-tab-pane>
                <el-tab-pane label="直播" name="three">
                    <div ref="televiseRef" style="width: 100%;height: 400px;"></div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StudentHome',
    components: {},
    props: {},
    data() {
        return {
          role: Number(localStorage.getItem('role')),
            contentData: [
                {
                    src: require('../../../assets/image/studentHome/paishe.png'),
                    dataName: '总直播场次',
                    num: 725,
                    moonNum: '700',
                    bigSrc: require('../../../assets/image/studentHome/big-paishe.png'),
                    moonsTip: '本月直播场次'
                },
                {
                    src: require('../../../assets/image/studentHome/kaishibofang.png'),
                    dataName: '总观看人数',
                    num: 2524,
                    moonNum: '511',
                    bigSrc: require('../../../assets/image/studentHome/big-kaishibofang.png'),
                    moonsTip: '本月观看人数'
                },
                {
                    src: require('../../../assets/image/studentHome/xinxi.png'),
                    dataName: '总评论数',
                    num: 26921,
                    moonNum: '2511',
                    bigSrc: require('../../../assets/image/studentHome/big-xinxi.png'),
                    moonsTip: '本月评论数'
                },
                {
                    src: require('../../../assets/image/studentHome/dianzan.png'),
                    dataName: '总获赞数',
                    num: 16987,
                    moonNum: '700',
                    bigSrc: require('../../../assets/image/studentHome/big-dianzan.png'),
                    moonsTip: '本月获赞数'
                },

            ],
            activeName: 'one',
            severalData: [225, 300, 200],
            peopleData: [670, 592, 1262],
            televiseData: {
                score: [85, 52, 96],
                market: [659347, 2531, 963468]
            }
        }
    },
    computed: {
    },

    methods: {
        changeBack(e) {
            console.log(e.target.dataset.id);
            this.tabBackground = e.target.dataset.id
        },
        handleClick(tab, event) {
            this.activeName = tab.name
            this.$nextTick(() => {
                this.$echarts.getInstanceByDom(this.$refs.severalRef).resize()
                this.$echarts.getInstanceByDom(this.$refs.peopleRef).resize()
                this.$echarts.getInstanceByDom(this.$refs.televiseRef).resize()
            })
        },

        selfAdaption(Charts) {
            window.addEventListener("resize", function () {
                if (Charts) {  // 防止拉动窗口时，resize报错
                    Charts.resize();
                }
            });
        },

        severalChart(severalData) {  // 直播场次图
            let srCharts = this.$echarts.init(this.$refs.severalRef); //初始化
            let data = severalData;
            let xData = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月",
                "11月", "12月"];

            let option = { //配置
                title: {},
                tooltip: {
                    trigger: "axis",
                    padding: 0,
                    textStyle: {
                        color: "#C9CDD4",
                        fontSize: 14,
                        fontWeight: 400,
                    },
                    formatter: function (datas) {
                        let res = datas[0].name + "<br/>";
                        for (var i = 0, length = datas.length; i < length; i++) {
                            let val = datas[i].value * 1;
                            res += datas[i].seriesName + "：" + val + "<br/>";
                        }
                        return "<div style='background-color: rgba(0,0,0,0.7); padding: 6px 0 0 10px;border: 0; margin: 0; width: 136px; height: 55px; line-height: 26px;'>" + res + "</div>";
                    },
                },
                xAxis: {
                    data: xData,
                    axisTick: {
                        show: false, //不展示X坐标轴刻度
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#C9CDD4",
                            width: 1,
                        },
                    },
                    axisLabel: {
                        fontSize: '12px',
                        color: '#86909C',
                    },
                },
                yAxis: [
                    {
                        splitLine: {					//---grid 区域中的分隔线
                            show: true,					//---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                            lineStyle: {
                                color: '#E5E6EB',
                                width: 1,
                                type: 'dashed',
                            },
                        },
                        nameTextStyle: {
                            color: '#1D2129',
                            fontSize: '12px'
                        },
                        name: '场次',
                        position: 'left',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#C9CDD4",
                                width: 1,
                                type: "solid"
                            },
                        },
                        axisLabel: {
                            show: true,
                            interval: "auto",
                            fontSize: '12px',
                            color: '#86909C',
                            formatter: '{value}'
                        },
                    },
                    {
                        position: 'rigth',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#C9CDD4",
                                width: 1,
                                type: "solid"
                            },
                        },
                        nameTextStyle: {
                            color: '#86909C',
                            fontSize: '12px'
                        },
                    }
                ],
                series: [
                    {
                        type: "bar",
                        name: "场次",
                        data: data,
                        barWidth: 24,
                        itemStyle: {
                            color: "#71A3FF",
                            barBorderRadius: [6, 6, 0, 0],
                        },
                    },
                ],
            };
            srCharts.setOption(option);

            this.selfAdaption(srCharts)
        },
        peopleChart(peopleData) {  // 观看人数图
            let peoCharts = this.$echarts.init(this.$refs.peopleRef); //初始化
            let data = peopleData;
            let xData = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月",
                "11月", "12月"];

            let option = { //配置
                tooltip: {
                    trigger: "axis",
                    padding: 0,
                    textStyle: {
                        color: "#C9CDD4",
                        fontSize: 14,
                        fontWeight: 400,
                    },
                    formatter: function (datas) {
                        let res = datas[0].name + "<br/>";
                        for (var i = 0, length = datas.length; i < length; i++) {
                            let val = datas[i].value * 1;
                            res += datas[i].seriesName + "：" + val + "<br/>";
                        }
                        return "<div style='background-color: rgba(0,0,0,0.7); padding: 6px 0 0 10px;border: 0; margin: 0; width: 136px; height: 55px; line-height: 26px;'>" + res + "</div>";
                    },
                },
                xAxis: {
                    data: xData,
                    axisTick: {
                        show: false, //不展示X坐标轴刻度
                    },
                    nameTextStyle: {
                        color: '#86909C',
                        fontSize: '12px'
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#C9CDD4",
                            width: 1,
                        },
                    },
                    axisLabel: {
                        fontSize: '12px',
                        color: '#86909C',
                    }
                },
                yAxis: [
                    {
                        splitLine: {					//---grid 区域中的分隔线
                            show: true,					//---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                            lineStyle: {
                                color: '#E5E6EB',
                                width: 1,
                                type: 'dashed',
                            },
                        },
                        name: '数量',
                        position: 'left',
                        nameTextStyle: {
                            color: '#1D2129',
                            fontSize: '12px'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#C9CDD4",
                                width: 1,
                                type: "solid"
                            },
                        },
                        axisLabel: {
                            show: true,
                            interval: "auto",
                            fontSize: '12px',
                            color: '#86909C',
                            formatter: function (value) {
                                return value;
                            },
                        },
                    },
                    {
                        position: 'rigth',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#C9CDD4",
                                width: 1,
                                type: "solid"
                            },
                        },
                    }
                ],
                series: [
                    {
                        type: "bar",
                        name: "场次",
                        data: data,
                        barWidth: 24,
                        itemStyle: {
                            color: "#71A3FF",
                            barBorderRadius: [6, 6, 0, 0],
                        },
                    },
                ],
            };
            peoCharts.setOption(option);

            this.selfAdaption(peoCharts)
        },
        televiseChart(televiseData) {  // 直播数量图
            let telCharts = this.$echarts.init(this.$refs.televiseRef); //初始化
            let data = televiseData;
            let xData = ["第1次直播", "第2次直播", "第3次直播", "第4次直播", "第5次直播", "第6次直播", "第7次直播", "第8次直播", "第9次直播", "第10次直播", "第11次直播", "第12次直播"];

            let option = { //配置
                tooltip: {
                    trigger: "axis",
                    axisPointer: {

                    },
                    padding: 0,
                    textStyle: {
                        color: "#C9CDD4",
                        fontSize: 12,
                        fontWeight: 400,
                    },
                    formatter: function (datas) {
                        let res = datas[0].name + "<br/>";
                        for (var i = 0, length = datas.length; i < length; i++) {
                            let val = datas[i].value * 1;
                            if (i === 1) {
                                val = (datas[i].value * 1 / 10000).toFixed(2) + "w";
                            }
                            res += datas[i].seriesName + "：" + val + "<br/>";
                        }
                        return "<div style='background-color: rgba(0,0,0,0.7); padding: 6px 0 0 10px;border: 0; margin: 0; width: 136px; height: 73px; line-height: 24px;'>" + res + "</div>";
                    },
                },
                legend: { // 图例
                    top: 30, //相对于容器位置
                    orient: 'horizontal',
                    textStyle: {    //图例内容样式
                        fontSize: '12px',
                        color: '#1D2129'
                    },
                    itemWidth: 8,  // 图例的宽
                    itemHeight: 8,
                    data: [ //图例内容
                        {
                            name: '分值',
                            icon: 'rect', //图例外框样式
                        },
                        {
                            name: '销售额',
                            icon: 'rect',
                        }
                    ]
                },
                calculable: true,
                xAxis: {
                    nameTextStyle: {
                        color: '#86909C',
                        fontSize: '12px'
                    },
                    data: xData,
                    axisTick: {
                        show: false, //不展示X坐标轴刻度
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#C9CDD4",
                            width: 1,
                        },
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            fontSize: '12px',
                            color: '#86909C',
                        },
                    },
                },
                yAxis: [
                    {
                        splitLine: {					//---grid 区域中的分隔线
                            show: true,					//---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                            lineStyle: {
                                color: '#E5E6EB',
                                width: 1,
                                type: 'dashed',
                            },
                        },
                        name: '分值',
                        type: 'value',
                        // min: 0,
                        // max: 100,
                        nameLocation: "end",
                        nameGap: 15,
                        nameTextStyle: {
                            color: '#1D2129',
                            fontSize: '12px'
                        },
                        axisLabel: {
                            formatter: '{value}',
                            show: true,
                            textStyle: {
                                fontSize: '12px',
                                color: '#86909C',
                            }
                        },
                        axisLine: {  // Y轴线的颜色、和轴线的宽度
                            show: 'true',
                            lineStyle: {
                                color: '#C9CDD4',
                                width: 1
                            }
                        }
                    },
                    {
                        splitLine: {
                            show: false
                        },
                        // min: 0,
                        // max: 5,
                        name: '销售额',
                        type: 'value',
                        nameLocation: "end",
                        nameGap: 15,
                        nameTextStyle: {
                            color: '#1D2129',
                            fontSize: '12px'
                        },
                        axisLabel: {
                            formatter: function (value) {
                                return value / 10000 + "w"
                            },
                            show: true,
                            textStyle: {
                                fontSize: '12px',
                                color: '#86909C',
                            }
                        },
                        axisLine: {
                            show: 'true',
                            lineStyle: {
                                color: '#C9CDD4',
                                width: 1
                            }
                        }
                    }
                ],
                series: [
                    {
                        type: "bar",
                        name: "分值",
                        data: data.score,
                        barGap: 0,
                        barWidth: 24,
                        itemStyle: {
                            normal: {
                                color: "#71A3FF",
                                barBorderRadius: [6, 6, 0, 0],
                            }
                        },
                    },
                    {
                        type: "bar",
                        name: "销售额",
                        data: data.market,
                        barWidth: 24,
                        barGap: 0,
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: "#9F8BFF",
                                barBorderRadius: [6, 6, 0, 0],
                            }
                        },
                    },
                ],
            };
            telCharts.setOption(option);

            this.selfAdaption(telCharts)
        },

    },
    mounted() {
        this.severalChart(this.severalData)
        this.peopleChart(this.peopleData)
        this.televiseChart(this.televiseData)

      if (this.role === 4) {
        this.contentData = [
          {
            src: require('../../../assets/image/studentHome/paishe.png'),
            dataName: '总直播场次',
            num: 3541,
            moonNum: '1547',
            bigSrc: require('../../../assets/image/studentHome/big-paishe.png'),
            moonsTip: '本月直播场次'
          },
          {
            src: require('../../../assets/image/studentHome/kaishibofang.png'),
            dataName: '总观看人数',
            num: 548961,
            moonNum: '32597',
            bigSrc: require('../../../assets/image/studentHome/big-kaishibofang.png'),
            moonsTip: '本月观看人数'
          },
          {
            src: require('../../../assets/image/studentHome/xinxi.png'),
            dataName: '总评论数',
            num: 697534,
            moonNum: '3946',
            bigSrc: require('../../../assets/image/studentHome/big-xinxi.png'),
            moonsTip: '本月评论数'
          },
          {
            src: require('../../../assets/image/studentHome/dianzan.png'),
            dataName: '总获赞数',
            num: 749658,
            moonNum: '64925',
            bigSrc: require('../../../assets/image/studentHome/big-dianzan.png'),
            moonsTip: '本月获赞数'
          },

        ]
        this.severalData = [549, 1580, 1412]
        this.severalChart(this.severalData)
      }
    },
}
</script>

<style scoped lang="scss">
.StudentHome {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding: 30px;

    .home-title {
        font-size: 14px;
        color: #332D42;
        overflow: hidden;

        .schoolname,
        .roomname {
            float: left;
        }

        .schoolname {
            margin-right: 100px;
        }
    }

    .home-dataContent {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        .dataContent-item {
            margin: 0 33px 20px 0;
            padding: 12px 16px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            border-radius: 8px;
            min-width: calc(25% - 25px);

            &:last-child {
                background: linear-gradient(270deg, #4C9EFF 0%, #3885FF 100%);
                margin: 0 0 20px 0;
            }

            &:first-child {
                background: linear-gradient(270deg, #469BFF 0%, #3380FF 100%);
            }

            &:nth-child(2) {
                background: linear-gradient(270deg, #7780FF 0%, #5E65FF 100%);
            }

            &:nth-child(3) {
                background: linear-gradient(270deg, #10C0FF 0%, #0BA8FF 100%);
            }

            .item_left {
                display: flex;
                flex: 1;

                .icon {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                }

                .data {
                    padding-top: 10px;
                    color: #FFFFFF;
                    width: 120px;

                    .contentText {
                        font-size: 14px;
                        white-space: nowrap;
                    }

                    .contentNum {
                        font-size: 40px;
                        height: 56px;
                        padding-top: 8px;
                    }

                    .contentMoon {
                        font-size: 12px;
                        white-space: nowrap;
                    }
                }
            }

            .bigicon {
                opacity: 0.5;
                margin-left: 10px;
            }
        }
    }

    .homeTab {
        :deep(.el-tabs__header) {
            border-bottom: none;
            border-radius: 4px;

            .el-tabs__item {
                border-color: #2821FC;
                border-top: 1px solid #2821FC;

                &:first-child {
                    border-left: 1px solid #2821FC;
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-right: 1px solid #2821FC;
                    border-radius: 0 4px 4px 0;
                }

                &.is-active {
                    background-color: #2821FC;
                    color: #FFFFFF;
                }
            }
        }

        // ::v-deep .el-tabs__header {
        //     border-bottom: none;
        // }
    }
}</style>
